.skill_filter_overview {
  .skill_filter {
    border-radius: 13px;

    .filter_heading {
      color: var(--secondary-light);

      @media screen and (max-width: 992px) {
        margin-top: 1rem;
      }
    }
  }
}

.Employee_Skills_List {
  .skills_list {
    .card-header {
      background-color: var(--secondary-light);
      border-radius: 10px 10px 0 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .card-title {
      color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0;

      font-size: 18px;
      @media only screen and (max-width: 1400px) and (min-width: 1200px) {
        font-size: 16px;
      }
      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }
  }
}
