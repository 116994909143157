.user_management {
    .card {
      border: none;
      border-radius: 12px;
      .card-text {
        margin-bottom: 0;
        margin-left: 10px;
      }
      .card_icon {
        color: var(--secondary-light);
      }
      .card-title {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        svg {
          vertical-align: baseline;
        }
      }
      .card-body {
        p {
          font-size: 15px;
        }
      }
    }
  }
  .upload_card {
    span {
      font-size: 14px;
    }
    @media screen and (max-width: 575px) {
      font-size: 14px !important;
    }
  }
  
  .user__profile {
    .user__heading {
      .profile__img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background: #455a64;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 40px;
      }
    }
    .personal__info,
    .employee__information {
      h5 {
        color: var(--secondary);
      }
      p {
        margin-bottom: 10px;
      }
      a {
        text-decoration: none;
      }
    }
  }
  .wFHDetails{
    font-size: 15;
    color: green;
    font-weight: bold;
  }
  .leaveDetails{
    font-size: 15;
    color: red;
    font-weight: bold;
  }

  // .modal_view{
  //   .modal_view_header{
  //     display: flex;
  //     justify-content: space-between;
  //     align-items: center;
  //   }
  // }
  .icon{
    margin-right: 5px;
  }

  .searchDate{
    align-items: flex-end;
     justify-content: flex-end;

     @media only screen and (min-width: 768px) and (max-width: 1115px) {
      align-items: flex-end;
      margin-right: 20px;
    }
  }
  .searchDateStyle{
    padding-right: 0px;
  }
  .searchDateButton{
    margin-top: 10px;
    margin-right: 10px;
    @media only screen and (max-width: 768px)  {
      margin-right: 0px;
    }
  }


 