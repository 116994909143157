.leave__overview__container {
  .card-header {
    background: var(--secondary-light);
    border-radius: 13px 13px 0 0;
    .card-title {
      color: var(--primary);
      margin-bottom: 0;
    }
  }
}

.backButton {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  align-items: center;
 
  .arrowLeft{
    cursor: pointer;
  }

  p {
    margin-left: 10px;
    margin-bottom: 0rem;
  }
}

.daterange_leavelist {
  .leave_list {
    .card-header {
      background-color: var(--secondary-light);
      border-radius: 13px 13px 0 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .card-title {
      color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0;
    }
  }
}
