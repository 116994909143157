.button_control {
  .btn-primary.secondary {
    border: 1px solid var(--secondary);
    background-color: var(--secondary);
    color: var(--primary);
    &:focus {
      box-shadow: none;
    }
  }
  .btn-primary.primal {
    color: var(--secondary);
    background-color: var(--primary);
    border: 1px solid var(--secondary);
    &:hover {
      color: var(--primary);
      background-color: var(--secondary);
    }
    &:focus {
      box-shadow: none;
    }
  }
  .btn-primary.danger {
    color: #fff;
    background-color: red;
    border: 1px solid red;
    &:focus {
      box-shadow: none;
    }
  }
  .btn-primary.dangerr {
    color: #fff;
    background-color: rgb(233, 95, 95);
    border: 1px solid rgb(233, 95, 95);
    &:focus {
      box-shadow: none;
    }
  }
  .btn-primary.danger-outline {
    color: red;
    background-color: var(--primary);
    border: 1px solid red;
    &:focus {
      box-shadow: none;
    }
  }
  .btn-primary.olivedrab-outline {
    color: olivedrab;
    background-color: var(--primary);
    border: 1px solid olivedrab;
    &:focus {
      box-shadow: none;
    }
  }
  .btn-primary.success{
    color: white;
    background-color: green;
    border: 1px solid green;
    &:focus {
      box-shadow: none;
    }
  }

  .btn-primary.small {
    font-size: 12px;
    @media only screen and (max-width: 1400px) and (min-width: 1200px) {
      font-size: 10px;
    }
  }
  .btn-primary.medium {
    font-size: 14px;
    width: 100px;
    @media only screen and (max-width: 1400px) and (min-width: 1200px) {
      font-size: 12px;
    }
  }
  .btn-primary.large {
    font-size: 14px;
    width: 160px;
    @media only screen and (max-width: 1400px) and (min-width: 1200px) {
      font-size: 12px;
    }
  }
}

.button_tooltip {
  font-size: 12px !important;
}
