.role_management,
.location_management,
.permission_management {
  .card {
    border-radius: 13px;

    .card-header {
      background-color: transparent;
      margin-bottom: 0 !important;
      color: var(--secondary);
      border-bottom: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 0;

      .card-title {
        margin-bottom: 0 !important;
        @media screen and (max-width: 500px) {
          font-size: 16px;
        }
      }
    }
  }
}

.admin_heading {
  h4 {
    color: var(--secondary);
    margin-bottom: 0;
    font-weight: bold;
  }
}

.role_management,
.location_management,
.permission_management {
  .card {
    border-radius: 13px;

    .card-header {
      background-color: transparent;
      margin-bottom: 0 !important;
      color: var(--secondary);
      border-bottom: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 0;

      .card-title {
        margin-bottom: 0 !important;
        @media screen and (max-width: 500px) {
          font-size: 16px;
        }
      }
    }
  }
}

.location_management {
  .card {
    border-radius: 13px;

    .card-header {
      background-color: transparent;
      margin-bottom: 0 !important;
      color: var(--secondary);
      border-bottom: none;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .card-title {
        margin-bottom: 0 !important;
        @media screen and (max-width: 500px) {
          font-size: 16px;
        }
      }
    }
  }
}

.location__column {
  p {
    margin-bottom: 0;
  }
}
