

.sidenav_wrapper {
  width: 230px;
  position: -webkit-sticky;
  position: fixed;
  background: var(--secondary-light);
  color: var(--primary) !important;
  top: 0;
  left: 0;
  z-index: 999;

  @media only screen and (max-width: 1400px) and (min-width: 1200px) {
    width: 212px;
  }
  @media only screen and (min-width: 1401px) {
    width: 260px;
  }

  .navbar-light .navbar-brand {
    display: flex;
    align-items: center;
    justify-content: center;

    .img {
      -o-object-fit: contain;
      object-fit: contain;
      width: 90%;
      height: auto;
    }
  }

 
  hr {
    margin: 0.2rem 0 0.2rem;
  }
  .active {
    background-color: var(--secondary-dark);
    font-weight: bold;
    color: var(--secondary) !important;
  }
  &_navbar {
    position: relative;
    height: 100vh;
    display: block;

    .settings {
      position: absolute;
      bottom: 0.5rem;
      left: 78px;
      transform: translateX(-50%);
      @media only screen and (max-width: 1400px) and (min-width: 1200px) {
        left: 90px;
      }
      .dropdown-menu {
        top: -80px;
      }
      .nav-link {
        color: var(--primary);
        @media only screen and (max-width: 1400px) and (min-width: 1200px) {
          font-size: 14px;
          padding: 0.5rem 0.5rem;
        }
      }
    }
  }
  svg {
    vertical-align: middle !important;
  }
}

  .flex_column_scroll {
  
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #242034;

      box-shadow: inset 0 0 2px #C2CBDF;
      border-radius: 10px;
    }
   
    }
  // }


.navbar-light .navbar-brand {
  color: var(--primary);
  margin-right: 0;
}
.navbar-light .navbar-nav .nav-link {
  color: var(--primary);
  font-size: 15px;
  border-radius: 8px;

  @media only screen and (max-width: 1400px) and (min-width: 1200px) {
    font-size: 13px;
  }
  @media only screen and (max-width: 1194px) and (min-width: 771px) {
    font-size: 12px;
    padding: 4px 10px;
  }

  &:hover {
    font-weight: bold;
    color: var(--secondary);
    background-color: var(--secondary-dark);
  }

  &:focus {
    color: var(--primary);
  }
}

.bottomnav_wrapper {
  .dropdown-toggle::after {
    content: none;
  }

  .navbar {
    background: var(--secondary);
    padding-bottom: 0;
    padding-top: 0;
    z-index: 999;
    .nav-link {
      color: var(--primary);
      // background-color: red;
    }
  }
  .active {
    color: var(--secondary-light) !important;
  }
}
.profile_link {
  padding-right: 3.5rem;
}

.side_nav_content {
  display: inline-block;
  position: relative;
  top: 1px;
  left: 10px;
  @media only screen and (max-width: 1400px) and (min-width: 1200px) {
    top: 0px;
  }
}
