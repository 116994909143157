.lunch_management {

  .lunch_managament_card {
    .card {
      border: none;
      border-radius: 12px;
      .card-title {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        svg {
          vertical-align: baseline;
        }
      }
      .card-text {
        margin: 21px 0;
      }
      .card_icon {
        color: var(--secondary-light);
        vertical-align: baseline;
      }
    }
  }
}