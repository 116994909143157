.wrapper {
  position: relative;
}

.auto_complete {
  input {
    display: block;
    height: 40px; // 42px;
    line-height: 32px;
    font-size: 13px;
    -webkit-appearance: none;
    font-weight: 300;
    width: 100%;
    color: #070707;
    padding: 0 10px 0 15px;
    text-overflow: ellipsis;
    border-radius: 4px;
    border: solid 1px #e0e0e0;
    background-color: var(--white);
    outline: var(--blue-button);
    font-family: Poppins, sans-serif;

    @media only screen and (max-width: 1400px) and (min-width: 1200px) {
      height: 36px;
      line-height: 28px;
      font-size: 11px;
    }

    // @media screen and (max-width: 575px) {
    //   height: 36px;
    //   line-height: 28px;
    // }

    &::placeholder {
      color: #070707;
      font-size: 13px;
      font-weight: 300;
      opacity: 0.5;
      @media only screen and (max-width: 1400px) and (min-width: 1200px) {
        font-size: 11px;
      }
    }
    &:focus {
      // font-size: 13px;
      border: solid 1px var(--secondary);
    }
  }
  .input_error {
    background-color: #fff3f5;
    border: solid 1px rgba(255, 0, 28, 0.25);
  }
  .error {
    text-transform: none;
    position: absolute;
    top: calc(100% + 2px);
    font-size: 10px;
    color: red;
  }
}

.bgColor {
  background-color: #f4f5f7 !important;
}

.menu {
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100% - 4px);
  z-index: 9;
  background-color: white;
  box-shadow: 0 4px 11px hsla(0, 0%, 0%, 0.1);
  border: solid 1px #e0e0e0;
  border-radius: 0 0 4px 4px;
  max-height: 200px;
  overflow: auto;
  div {
    font-size: 15px !important;
    padding: 2px 5px;
    cursor: pointer;
  }
}

.item {
  padding: 7px 10px;
  cursor: pointer;
  //border-right: solid 1px #e0e0e0;
  //border-left: solid 1px #e0e0e0;
  //border-bottom: none;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &Label {
    padding-left: 20px;
    text-align: right;
    font-size: 13px;
  }

  &:first-child {
    position: relative;
    //border-top: solid 1px #e0e0e0;
  }

  &:last-child {
    position: relative;
    //border-bottom: solid 1px #e0e0e0;
    //border-radius: 0 0 4px 4px;
  }

  &:hover {
    background-color: #eaf6f5;
  }
}

.loading {
  position: absolute;
  top: 8px;
  right: 20px;
}
