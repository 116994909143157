.event_calender {
    border-radius: 13px;
  
    .fc .fc-button-primary {
      background-color: var(--secondary);
      border-color: var(--secondary);
  
      &:hover {
        background-color: var(--secondary);
        border-color: var(--secondary);
      }
      &:focus {
        background-color: var(--secondary);
        border-color: var(--secondary);
        box-shadow: none;
      }
    }
  
    .fc .fc-button .fc-icon {
      font-size: 1rem;
    }
  
    // .fc td, .fc th {
    //   border-style: none !important;
    // }
  
    .fc td,
    .fc th {
      border-color: #ebf1ff !important;
    }
  
    .fc .fc-toolbar-title {
      color: var(--secondary-light);
    }
  
    .fc .fc-highlight {
      border: 1px solid var(--secondary-light);
    }
  
    .fc .fc-scrollgrid-section table,
    .fc-daygrid-body.fc-daygrid-body-unbalanced {
      width: 100% !important;
    }
  
    // .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
    //   min-height: 0;
    // }
    .fc .fc-daygrid-body-natural .fc-daygrid-day-events {
      margin-bottom: 0;
    }
    .fc .fc-daygrid-day-number {
      text-decoration: none;
      color: #263871;
    }
  
    .fc-col-header-cell-cushion {
      text-decoration: none;
      color: var(--secondary-light);
    }
  
    .fc .fc-button {
      box-shadow: none !important;
    }
    .fc .fc-button:focus {
      box-shadow: none !important;
    }
    .fc-prev-button.fc-button.fc-button-primary,
    .fc-next-button.fc-button.fc-button-primary {
      background-color: var(--secondary);
      border-color: var(--secondary);
      &:hover {
        background-color: var(--secondary);
        border-color: var(--secondary);
      }
      &:focus {
        background-color: var(--secondary);
        border-color: var(--secondary);
      }
    }
  
    .fc .fc-daygrid-day-top {
      display: block;
    }
  
    .fc .fc-daygrid-day.fc-day-today {
      background-color: #d87d2e;
      opacity: 0.9;
    }
  }
  
  .column_divider {
    display: flex;
    flex-direction: column;
  
    .symbols {
      padding-bottom: 20px;
      .card {
        border-radius: 13px;
  
        .dot_label {
          border-radius: 3px;
          display: block;
          height: 16px;
          width: 16px;
  
          &--maroon {
            background-color: #cc0066;
          }
          &--blue {
            background-color: #004c99;
          }
          &--green {
            background-color: #009900;
          }
          &--red {
            background-color: #ff3333;
          }
          &--orange {
            background-color: #ffa600;
          }
        }
      }
    }
  
    .leave_overview {
      &_contents {
        height: 150px;
        max-height: 200px;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        display: block;
        overflow-x: auto;
        width: 100%;
        border: 0;
        padding-right: 10px;
        &::-webkit-scrollbar {
          height: 5px;
          width: 10px;
        }
  
        &::-webkit-scrollbar-track {
          box-shadow: none;
          border-radius: 10px;
        }
  
        &::-webkit-scrollbar-thumb {
          background-color: #c2cbdf;
          box-shadow: inset 0 0 2px #c2cbdf;
          border-radius: 10px;
        }
      }
      padding-bottom: 20px;
      .border_divider {
        border-left: 1px solid #000;
        @media screen and (max-width: 992px) {
          border-top: 1px solid #000;
          border-left: none;
          margin-top: 1rem;//separation of big card during responsive
        }
      }
      .card {
        border-radius: 13px;
  
        .today_date {
          font-size: 20px;
          @media screen and (max-width: 992px) {
            font-size: 16px;
            margin-top: 1rem;
          }
        }
      }
    }
  
    .my_leave_applications {
      .card {
        word-wrap: break-word;
        background-clip: border-box;
        background-color: #fff;
        border: 0;
        border-radius: 13px;
        box-shadow: 0 0.15rem 1.75rem 0 rgba(196, 205, 224, 0.2);
        display: flex;
        flex-direction: column;
        min-width: 0;
        position: relative;
        width: 100%;
        .card-header,
        .card-header .card-title {
          margin-bottom: 0;
        }
        .card-header {
          align-items: center;
          background: transparent;
          border-bottom: 0.5px solid #e9ebfa;
          display: flex;
          width: 100%;
          color: var(--secondary);
          justify-content: space-between;
          align-items: center;
          padding: 1.5rem 1.5rem 0.5rem;
          // @media screen and (max-width: 767px) {
          //   .card-title {
          //     font-size: 14px;
          //   }
          // }
        }
        .table_tabs {
          border: 0 !important;
          border-bottom: 1px solid #e9ebfa !important;
          padding: 0 1rem;
        }
        .nav-tabs .nav-link {
          font-size: 14px;
          background: #ebeff8;
          border-radius: 8px 8px 0 0;
          margin: 0 0.3rem -1px;
          color: var(--secondary-light);
          @media screen and (max-width: 767px) {
            width: 100%;
            font-size: 12px;
            margin: 5px 0;
            border-radius: 8px;
            border-bottom: 0.5px solid #e9ebfa !important;
          }
        }
        .nav-tabs .nav-link.active {
          background: #fff;
        }
        .nav-tabs .nav-link:hover {
          border-bottom: 1px solid #fff;
        }
        .attendance_table {
          // height: 300px;
          // max-height: 300px;
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;
          -ms-overflow-style: -ms-autohiding-scrollbar;
          display: block;
          overflow-x: auto;
          width: 100%;
          border: 0;
          &::-webkit-scrollbar {
            height: 10px;
            width: 10px;
          }
  
          &::-webkit-scrollbar-track {
            box-shadow: none;
            border-radius: 10px;
          }
  
          &::-webkit-scrollbar-thumb {
            background-color: #c2cbdf;
            box-shadow: inset 0 0 2px #c2cbdf;
            border-radius: 10px;
          }
        }
        .border-top {
          border-top: 0.5px solid #e9ebfa !important;
        }
        .border-bottom {
          border-bottom: 0.5px solid #e9ebfa !important;
        }
        .table {
          color: #313e6a;
          th {
            font-size: 14px;
          }
          td {
            font-size: 14px;
            font-weight: 400;
            padding: 0.75rem;
            vertical-align: middle;
          }
        }
        .table > :not(:last-child) > :last-child > * {
          border-bottom: 0.5px solid #e9ebfa !important;
        }
        .table > :not(caption) > * > * {
          border-bottom-width: 0;
        }
      }
    }
  }
  
  .table-responsive {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    display: block;
    overflow-x: auto;
    width: 100%;
    border: 0;
    &::-webkit-scrollbar {
      height: 10px;
      width: 10px;
    }
  
    &::-webkit-scrollbar-track {
      box-shadow: none;
      border-radius: 10px;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: #c2cbdf;
      box-shadow: inset 0 0 2px #c2cbdf;
      border-radius: 10px;
    }
  }
  
  .calender_symbols_content {
    font-size: 11px;
  }
  
  .holiday__list {
    &--regular,
    &--optional {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--secondary-light);
      @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: start;
      }
    }
  }
  
  .tabLoaderApplyForWfh{
    height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
   
  }