.technology_management,
.category_management,
.proficiecny_management {
  .card {
    border-radius: 13px;

    .card-header {
      background-color: transparent;
      margin-bottom: 0 !important;
      color: var(--secondary);
      border-bottom: none;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .card-title {
        margin-bottom: 0 !important;
        @media screen and (max-width: 500px) {
          font-size: 16px;
        }
      }
    }
  }
}
