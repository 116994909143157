.page {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px;

  p {
    margin-bottom: 0;
  }

  .pagText {
    white-space: nowrap;
    font-size: 14px;
    color: #6b6c70;
    // margin-right: 10px;
    text-transform: uppercase;
    display: none;

    @media screen and (max-width: 767px) {
      font-size: 12px;
      margin-right: 5px;
    }
    @media only screen and (max-width: 1400px) and (min-width: 900px) {
      font-size: 12px;
      margin-right: 5px;
    }
  }

  .pagBtn {
    flex-shrink: 0;
    margin-left: 7px;
    height: 42px;
    width: 48px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 14px;
    text-transform: uppercase;
    letter-spacing: 0.15px;
    text-align: center;
    cursor: pointer;
    background-color: #eaeaea;
    color: var(--secondary);
    background-color: var(--primary);
    border: 1px solid var(--secondary);

    @media screen and (max-width: 767px) {
      height: 30px;
      width: 30px;
      font-size: 11px;
      line-height: 11px;
    }
    @media screen and (max-width: 767px) {
      height: 35px;
      width: 35px;
      font-size: 11px;
      line-height: 11px;
    }
    @media only screen and (max-width: 1400px) and (min-width: 900px) {
      height: 35px;
      width: 35px;
      font-size: 11px;
      line-height: 11px;
    }
  }

  .pagBtn.-big {
    letter-spacing: 0.15px;
    color: #6b6c70;
    width: 99px;
    background-color: #fff;
    border: solid 1px rgba(143,143,145,0.31);

    @media screen and (max-width: 767px) {
      width: 50px;
    }

    @media screen and (max-width: 1280px) {
      width: 70px;
    }
    @media only screen and (max-width: 1400px) and (min-width: 900px) {
      width: 60px;
    }
  }

  .pagBtn.-arrow {
    background-color: #fff;
    border: solid 1px rgba(143,143,145,0.31);

    &:focus {
      border: none;
      outline: none;
    }
  }

  .pagBtn.-num {
    color: #2C2C2C;
    border: none;
  }

  .pagBtn.-active {
    border: none;
    color: #fff;
    background-color: var(--secondary);
  }

  .pagPoints {
    height: 42px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-left: 7px;
  }
}