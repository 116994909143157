.circular_btn {
  background-color: var(--secondary);
  color: var(--primary);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);
  border: none;
  outline: none;
  &:focus {
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);
  }
  @media screen and (max-width: 600px) {
    margin: 0 2px;
  }
}

.circular_small_btn {
  background-color: var(--secondary);
  color: var(--primary);
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);
  border: none;
  outline: none;
  &:focus {
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);
  }
  @media screen and (max-width: 600px) {
    margin: 0 2px;
  }
}
.circukar_tooltip {
  font-size: 12px !important;
}
