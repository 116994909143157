.lunch_preference_card {
  .card {
    border: none;
    border-radius: 12px;
    .card-title {
      color: var(--secondary);
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        vertical-align: baseline;
      }
    }
    .card-text {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .card_icon {
      color: var(--secondary-light);
    }
  }
}

// .main__body {
//   .card {
//     border: none;
//     border-radius: 12px;
//     min-height: 220px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     .card-body {
//       width: 100%;
//       flex: none;
//     }
//   }
//   .card-title {
//     color: var(--secondary);
//     svg {
//       vertical-align: baseline;
//     }
//   }
//   .card_icon {
//     color: var(--secondary-light);
//   }
// }