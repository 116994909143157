.my_skills_overview {

  @media screen and (min-width: 1030px) {
    margin-top: 1rem;
  }

  .Technology_Title {
    color: #d87d2e;
    font-weight: bolder;
    text-transform: uppercase;
  }
}