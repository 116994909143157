/* .row > * {
  padding-right: 0 !important;
  padding-left: 0 !important;
} */
.form_main {
  .row > * {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}
.join_us_embedded_form {
  background-color: #f4f3f3;
  font-family:Poppins, "Roboto", sans-serif !important;
  width: 100%;
  max-width: 700px;
  margin: 0px auto;
  padding: 0 30px;
  // position: absolute;
  // top: 40%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  & .heading {
    color: #ed7715;
    font-weight: 300;
    font-size: 36px;
    display: inline-block;
    width: 100%;
  }
}
.form_field {
  margin: 6px 5px;
  position: relative;
  .show_error_line {
    border-color: red;
  }
  input,
  select {
    color: #222;
    font-size: 18px;
    font-weight: 300;
    outline: none;
    border: 0;
    border-bottom: 1px solid #666;
    background: 0 0;
    padding: 10px;
    width: 100%;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    resize: none;
  }
  input:focus,
  select:focus {
    border-color: #ed7715;
  }
}

.error_msg {
  position: absolute;
  font-size: 12px;
  line-height: 16px;
  color: red;
  font-weight: 400;
  display: block;
  font-family: Poppins,"Gotham-Book";
}

.file-input {
  display: inline-block;
  text-align: left;
  padding: 10px;
  width: 100%;
  position: relative;
  border-bottom: 1px solid #666;
  display: flex;
  justify-content: space-between;
}

.file-input > [type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 10;
  cursor: pointer;
}
.file-input {
  .label {
    color: #333;
    white-space: nowrap;
    opacity: 0.5;
    overflow: hidden;
    margin-right: 20px;
    font-size: 16px;
  }
}

.logo {
  img {
    position: absolute;
    top: 3px;
    right: 0;
    width: 43px;
    height: auto;
  }
}

.form_submit {
  input {
    width: 100%;
    color: #fff;
    text-align: center;
    margin-top: 50px;
    font-size: 18px;
    font-weight: 400;
    background: #ed7715;
    padding: 10px 35px;
    border: 1px solid transparent;
    display: inline-block;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    text-transform: uppercase;
  }
  input:hover {
    border: 1px solid #ed7715;
    background: transparent;
    color: #ed7715;
  }
}

.bg_img {
  background: url(../../../assets/images/pexels-photo-590016-e1514440218116.jpeg)
    no-repeat center center;
  height: 100%;
}

.success {
  color: green;
}
.warning {
  color: red;
}

.notification_msg {
  // position: absolute;
  bottom: 0;
  right: 5px;
}

/* responsive css */

@media screen and (max-width: 1600px) {
  .form_field input,
  .form_field select {
    font-size: 16px;
  }
  .form_submit input {
    margin-top: 20px;
    font-size: 16px;
  }
}
@media screen and (max-width: 900px) {
  .bg_img {
    height: 500px;
  }
  .join_us_embedded_form {
    top: 40%;
  }
  .join_us_embedded_form .heading {
    font-size: 26px;
  }
  .form_field input,
  .form_field select {
    font-size: 14px;
  }
  .form_submit input {
    font-size: 14px;
  }
  .file-input {
    .label {
      font-size: 14px;
    }
  }
  .logo {
    img {
      position: absolute;
      top: 3px;
      right: 0;
      width: 40px;
      height: auto;
    }
  }
}
@media screen and (max-width: 575px) {
  .join_us_embedded_form {
    top: 50%;
  }
}
