.b-datePicker {
  width: 100%;
  height: 100%;
  cursor: pointer;
  border: solid 1px #e0e0e0;
  border-radius: 4px;
  font-family: Poppins, sans-serif;

  &__wrapper {
    position: relative;
    height: 40px;
    &.sm {
      width: 145px;
      @media screen and (max-width: 575px) {
        width: 100%;
      }
    }
    @media screen and (max-width: 575px) {
      height: 36px;
      margin-bottom: 15px;
    }
    @media only screen and (max-width: 1400px) and (min-width: 1200px) {
      height: 36px;
      line-height: 28px;
      font-size: 11px;
    }

    &.range {
      @media screen and (max-width: 480px) {
        width: 145px;
        .b-datePicker {
          .react-date-picker__wrapper {
            padding-left: 2px;
            padding-right: 0;
            font-size: 10px;
          }

          .react-date-picker__button {
            padding: 3px;
          }

          .react-date-picker__clear-button {
            padding: 0;
            font-size: 16px;
          }

          .react-date-picker__inputGroup__input {
            font-size: 10px;
          }

          .react-date-picker__calendar {
            min-width: 170px;
            left: auto;
            right: auto;
          }

          &__calendar-icon {
            font-size: 14px;
          }
        }
      }
    }
  }

  &__label {
    font-size: 12px;
    margin-bottom: 3px;
    font-weight: bold;
    line-height: normal;
    margin-left: 6px;
    display: block;
    color: #6c757d !important;
    @media only screen and (max-width: 1400px) and (min-width: 1200px) {
      font-size: 11px;
    }
  }

  .react-date-picker__wrapper {
    padding-left: 13px;
    padding-right: 2px;
    width: 100%;
    font-size: 13px;
    color: #070707;
    border-radius: 4px;
    border: none;
  }

  &__calendar-icon {
    font-size: 20px;
    color: var(--blue);
  }

  &__item {
    color: var(--grey);
    padding: 5px 0px !important;
    font-size: 10px;
  }

  // .react-date-picker__calendar {
  //   width: 110%;
  //   top: calc(100% - 2px) !important;
  //   left: 0;
  //   right: 0;
  // }

  .react-date-picker__inputGroup__input {
    color: #070707;
    text-transform: uppercase;
    font-family: Poppins, sans-serif;

    &::placeholder {
      color: #070707;
      opacity: 0.5;
    }
  }

  .react-date-picker__inputGroup__input:invalid {
    background: transparent;
  }

  .react-calendar {
    font-family: Poppins,Arial, Helvetica, sans-serif;
    border-radius: 0 0 4px 4px;
    border: solid 1px #e0e0e0;
    border-top: 0;
    background-color: #fff;
  }

  .react-calendar__month-view__weekdays__weekday {
    color: var(--blue-button);
    font-size: 8px;
  }

  .react-calendar__navigation {
    height: 28px;
    background-color: var(--grey);
    text-align: center;
    margin-bottom: 0;
    margin-top: 2px;
    border-top: solid 1px #e0e0e0;

    button {
      font-size: 12px;
      color: var(--light-grey);
    }
  }

  .react-date-picker__clear-button {
    padding: 0;
    color: var(--blue);
    font-size: 20px;
  }

  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: var(--grey);
  }

  .react-calendar__month-view__days__day--weekend {
    color: var(--grey);
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    opacity: 0.5;
  }
}

.react-date-picker {
  display: inline-flex;
  position: relative;
}

.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-date-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
}

.react-date-picker__wrapper {
  display: flex;
  border: thin solid gray;
}

.react-date-picker__inputGroup {
  min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
  flex-grow: 1;
  display: flex;
  padding: 0 2px;
  align-items: baseline;
  box-sizing: content-box;
}

.react-date-picker__inputGroup__divider {
  color: #070707;
  font-size: 10px;
  opacity: 0.7;
  padding: 1px 0;
  white-space: pre;
}

.react-date-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;
}

.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-date-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}

.react-date-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}

.react-date-picker__button {
  //position: absolute;
  //z-index: -11;
  border: 0;
  background: transparent;
  padding: 4px 6px;
}

.react-date-picker__button:enabled {
  cursor: pointer;
}

.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: #0078d7;
}

.react-date-picker__button:disabled .react-date-picker__button__icon {
  stroke: #6d6d6d;
}

.react-date-picker__button svg {
  display: inherit;
}

.react-date-picker__calendar {
  width: 350px;
  max-width: 100vw;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
}

.react-date-picker__calendar--closed {
  display: none;
}

.react-date-picker__calendar .react-calendar {
  border-width: thin;
}

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Poppins,Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000 !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: #006edc;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff !important;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.dat_error {
  text-transform: none;
  position: absolute;
  top: calc(100% + 18px);
  font-size: 10px;
  color: red;
  @media only screen and (max-width: 1400px) and (min-width: 1200px) {
    top: calc(100% + 10px);
    font-size: 9px;
  }
}

.date_error {
  background-color: #fff3f5;
  border: solid 1px rgba(199, 1, 24, 0.25) !important;
}
// .b-datePicker .react-date-picker__calendar {
//   overflow: auto;
//   overflow-y: hidden;
// }
