// .loader {
//   height: 100vh;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }
// .smallLoader {
//   height: 200px;
// }


.loader {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.smallLoader {
  height: 200px;
}

// .miniLoader{
//   display: block;
//    width: 700px;
//     padding: 180px;
//     margin-left:130px ; 
  
// }
