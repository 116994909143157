.user_management {
  .card {
    border: none;
    border-radius: 12px;
    .card-text {
      margin-bottom: 0;
      margin-left: 10px;
    }
    .card_icon {
      color: var(--secondary-light);
    }
    .card-title {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      svg {
        vertical-align: baseline;
      }
    }
    .card-body {
      p {
        font-size: 15px;
      }
    }
  }
}
.upload_card {
  span {
    font-size: 14px;
  }
  @media screen and (max-width: 575px) {
    font-size: 14px !important;
  }
}

.user__profile {
  .user__heading {
    .profile__img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background: #455a64;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 40px;
    }
  }
  .personal__info,
  .employee__information {
    h5 {
      color: var(--secondary);
      margin-bottom: 10px;
    }
    p {
      margin-bottom: -2px;
    }
    a {
      text-decoration: none;
    }
  }
}

.family__info {
  &--title {
    color: var(--secondary);
  }

  &--content {
    .family__content {
      &--header {
        display: flex;
        align-items: center;
      }
      &--title {
        font-size: 1.15rem;
        color: var(--secondary-light);
      }
      &--sub-title {
        color: var(--secondary-light);

        margin-bottom: 5px;
      }
    }
  }
}
.row,.bottom_row{
 
  padding: 3.5px;
  margin-bottom: 3px;
  word-wrap: break-word;
}
