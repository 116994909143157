.my_leave_applications {
    .card {
      word-wrap: break-word;
      background-clip: border-box;
      background-color: #fff;
      border: 0;
      border-radius: 13px;
      box-shadow: 0 0.15rem 1.75rem 0 rgba(196, 205, 224, 0.2);
      display: flex;
      flex-direction: column;
      min-width: 0;
      position: relative;
      width: 100%;
      .card-header,
      .card-header .card-title {
        margin-bottom: 0;
      }
      .card-header {
        align-items: center;
        background: transparent;
        border-bottom: 0.5px solid #e9ebfa;
        display: flex;
        width: 100%;
        color: var(--secondary);
        justify-content: space-between;
        align-items: center;
        padding: 1.5rem 1.5rem 0.5rem;
        // @media screen and (max-width: 767px) {
        //   .card-title {
        //     font-size: 14px;
        //   }
        // }
      }
      .table_tabs {
        border: 0 !important;
        border-bottom: 1px solid #e9ebfa !important;
        padding: 0 1rem;
      }
      .nav-tabs .nav-link {
        font-size: 14px;
        background: #ebeff8;
        border-radius: 8px 8px 0 0;
        margin: 0 0.3rem -1px;
        color: var(--secondary-light);
        @media screen and (max-width: 767px) {
          width: 100%;
          font-size: 12px;
          margin: 5px 0;
          border-radius: 8px;
          border-bottom: 0.5px solid #e9ebfa !important;
        }
      }
      .nav-tabs .nav-link.active {
        background: #fff;
      }
      .nav-tabs .nav-link:hover {
        border-bottom: 1px solid #fff;
      }
      .attendance_table {
        // height: 300px;
        // max-height: 300px;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        display: block;
        overflow-x: auto;
        width: 100%;
        border: 0;
        &::-webkit-scrollbar {
          height: 10px;
          width: 10px;
        }

        &::-webkit-scrollbar-track {
          box-shadow: none;
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #c2cbdf;
          box-shadow: inset 0 0 2px #c2cbdf;
          border-radius: 10px;
        }
      }
      .border-top {
        border-top: 0.5px solid #e9ebfa !important;
      }
      .border-bottom {
        border-bottom: 0.5px solid #e9ebfa !important;
      }
      .table {
        color: #313e6a;
        th {
          font-size: 14px;
        }
        td {
          font-size: 14px;
          font-weight: 400;
          padding: 0.75rem;
          vertical-align: middle;
        }
      }
      .table > :not(:last-child) > :last-child > * {
        border-bottom: 0.5px solid #e9ebfa !important;
      }
      .table > :not(caption) > * > * {
        border-bottom-width: 0;
      }
    }
  }


  .My_chat_container{
    // border:2px solid grey;
    // border-radius: 5px;
    // margin: 5px 0;
    // padding: 10px;
    // background-color: burlywood;
    height: 408px;
    font-size: 13px;
  }
//   .containerr{
//     display: flex;
//   }
//  .me{
//     // display: flex;
//     flex-direction: row-reverse;
//   }
.chat-inner{
  display: flex;
  justify-content: space-between;
  
}
.My_chat_subject{
  font-size: 13px;
  font-weight: bold;
  color:#d87d2e ;

}

.viewScroll  {
  // &_contents {
    height: 460px;   //height of a card for scrolling
    // max-height: 570px;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c2cbdf;

      box-shadow: inset 0 0 2px #C2CBDF;
      border-radius: 10px;
    }
}