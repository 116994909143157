.job_filter_overview {
  .job_filter {
    border-radius: 13px;

    .filter_heading {
      color: var(--secondary-light);

      @media screen and (max-width: 992px) {
        margin-top: 1rem;
      }
    }
  }
}
.job_list_overview {
  .job_list {
    border-radius: 13px;
    .card-body {
      background-color: var(--secondary-light);
      border-radius: 10px 10px 0 0;
    }

    .card-title {
      color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0;
    }
  }
}

.job_detail_overview {
  .job_detail {
    border-radius: 13px;
    .card-body {
      background-color: var(--secondary-light);
      border-radius: 10px 10px 0 0;
    }
    .card-title {
      color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0;
    }

    .job_detail_content {
      max-height: 645px;
      overflow-x: auto;
      padding: 0.5rem 1rem 1rem;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      display: block;
      overflow-x: auto;
      width: 100%;
      &::-webkit-scrollbar {
        height: 10px;
        width: 10px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: none;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #c2cbdf;
        box-shadow: inset 0 0 2px #c2cbdf;
        border-radius: 10px;
      }

      .sheduled_meeting_list {
        // background-color: var(--bg-color);
        .table {
          font-size: 14px;
          margin-bottom: 0;
          // border-color: var(--bg-color);
          text-align: left;
        }
      }

      table {
        font-size: 14px;
      }
    }
  }
}

.add_job_application {
  hr {
    margin: 0;
  }
  .secondary_domain {
    min-height: 100px;
    overflow-x: auto;
    background-color: #ecf0f9;
    border-radius: 10px;
    padding: 5px;
    width: 100%;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
    }
    .secondary_domain_btn {
      border: 1px solid var(--secondary);
      background-color: var(--secondary);
      color: var(--primary);
      margin: 2px;
      border-radius: 5px;
      &:focus {
        box-shadow: none;
      }
    }
  }

  .referred_by {
    position: relative;

    &_error {
      font-size: 10px;
      color: red;
      position: absolute;
      width: 100%;
      height: 100%;
      top: calc(100%);
    }
  }

  h5 {
    font-size: 16px;
  }
}

.interview_schedule {
  &_table {
    // border: 1px solid grey !important;
    table {
      margin: 0;
      width: 100%;
      font-size: 11.5px;
      border: 1px solid #000;

      tbody {
        background-color: var(--bg-color);
      }

      th {
        font-size: 13px;
      }
    }
    max-height: 203px;
    @media only screen and (max-width: 1400px) and (min-width: 1200px) {
      max-height: 181px;
    }

    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    display: block;
    overflow-x: auto;
    width: 100%;
    &::-webkit-scrollbar {
      height: 10px;
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: none;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #c2cbdf;
      box-shadow: inset 0 0 2px #c2cbdf;
      border-radius: 10px;
    }
  }

  .interviewer {
    min-height: 100px;
    overflow-x: auto;
    background-color: #ecf0f9;
    border-radius: 10px;
    padding: 5px;
    width: 100%;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
    }
    &_btn {
      border: 1px solid var(--secondary);
      background-color: var(--secondary);
      color: var(--primary);
      margin: 2px;
      border-radius: 5px;
      &:focus {
        box-shadow: none;
      }
    }
  }
}

.job_detail_actions {
  h5 {
    font-size: 18px;
    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }
  .dropdown-toggle {
    padding: 0;
    background: #fff;
    color: #000;
    border: none;
    transition: none;
    cursor: pointer;
    &:after {
      content: none;
    }
    &:focus {
      background: #fff;
      color: #000;
      border: none;
      outline: none;
      box-shadow: none;
    }
  }
  .dropdown-item {
    &:focus {
      background: #fff;
      color: #000;
    }
  }
}

.job_app_title {
  font-size: 18px;
  @media only screen and (max-width: 1400px) and (min-width: 1200px) {
    font-size: 16px;
  }
  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
}

.job_list_overview {
  .card {
    .table_tabs {
      margin-top: 0.5rem;
      border: 0 !important;
      border-bottom: 1px solid #e9ebfa !important;
      padding: 0.2rem 1rem;
    }
    .nav-tabs .nav-link {
      font-size: 14px;
      background: #ebeff8;
      border-radius: 8px 8px 0 0;
      margin: 0 0.3rem -1px;
      color: var(--secondary-light);
      @media screen and (max-width: 767px) {
        width: 100%;
        font-size: 12px;
        margin: 5px 0;
        border-radius: 8px;
        border-bottom: 0.5px solid #e9ebfa !important;
      }
      @media only screen and (max-width: 1400px) and (min-width: 1200px) {
        font-size: 12px !important;
      }
    }
    .nav-tabs .nav-link.active {
      background: #fff;
    }
    .nav-tabs .nav-link:hover {
      border-bottom: 1px solid #fff;
    }
    .border-top {
      border-top: 0.5px solid #e9ebfa !important;
    }
    .border-bottom {
      border-bottom: 0.5px solid #e9ebfa !important;
    }
    .table {
      color: #313e6a;
      th {
        font-size: 14px;
      }
      td {
        font-size: 14px;
        font-weight: 400;
        padding: 0.75rem;
        vertical-align: middle;
      }
    }
    .table > :not(:last-child) > :last-child > * {
      border-bottom: 0.5px solid #e9ebfa !important;
    }
    .table > :not(caption) > * > * {
      border-bottom-width: 0;
    }
  }
}

.job_table_content {
  position: relative;

  .show_months_limit {
    position: absolute;
    top: 20px;
    right: 10px;

    @media screen and (max-width: 767px) {
      position: relative;
      right: -10px;
      margin-bottom: 20px;
    }
  }
}

.profile_detail_table {
  td {
    @media only screen and (max-width: 1400px) and (min-width: 1200px) {
      font-size: 13px;
    }
  }
}

.interview_schedule_table {
  li {
    color: grey;
  }
  .table {
    th {
      font-size: 14px;
      @media only screen and (max-width: 1400px) and (min-width: 1200px) {
        font-size: 13px;
      }
    }
  }
}

.hint {
  font-size: 13px;
  @media screen and (max-width: 767px) {
    font-size: 12px !important;
  }
}

.currently_employment_status {
  position: relative;

  &_error {
    font-size: 10px;
    color: red;
    position: absolute;
    width: 100%;
    height: 100%;
    top: calc(100%);
  }
}

.interview_schedule_depend_on_date_des {
  max-height: 120px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  display: block;
  overflow-x: auto;

  width: 100%;
  border: 0;
  .description_detail {
    font-size: 16px;
    @media only screen and (max-width: 1400px) and (min-width: 1200px) {
      font-size: 14px;
    }
  }
  &::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 10px;
    border: 1px solid #ededed;
    background-color: #ededed;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a6a6a6;
    box-shadow: inset 0 0 2px #a6a6a6;
    border-radius: 10px;
  }
}

.column_divider .card {
  border-radius: 13px;
}

.add_job_app_modal {
  position: relative;
}

.add_tech_domain {
  position: absolute;
  top: 50%;
  left: 50%;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  z-index: 9999999;

  &_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    width: 100%;
    max-width: 400px;

    .add_domain_title {
      padding: 1rem 1rem 0;
      margin-bottom: 0;
    }
  }
}
.tooltip_select{
  width: 240px;
}
.select__single-value > p{
      /* width: 184px; */
      max-width: 150px;
      text-overflow: ellipsis;
      line-height: 20px;
      margin-top: 19px;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden !important;
}
// .location-list-select {
//   .css-1uccc91-singleValue {
//     top: 0%;
//   }
// }
