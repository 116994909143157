.google_login {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(../../../assets/images/bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    // background: linear-gradient(to left, rgba(216, 125, 46, 0.90) 0%, rgba(36, 32, 52, 0.90) 100%);
    background: linear-gradient(to left, rgba(119, 57, 252, 0.92) 0%, rgba(58, 122, 254, 0.92) 100%);
  }

  &_container {
    width: 100%;
    max-width: 500px;
    margin: 0 20px;
    height: 400px;
    border-radius: 10px;
    @media only screen and (max-width: 1400px) and (min-width: 1200px) {
      max-width: 450px;
    height: 360px;
    }
    img {
      width: 100px;
      height: auto;
      @media only screen and (max-width: 1400px) and (min-width: 1200px) {
        width: 90px;
      }
    }
    h4 {
      color: var(--secondary);
      font-weight: bold;
    }
    .card-title {
      color: var(--secondary-light);
    }
    button {
      background: transparent;
      outline: none;
      border: none;
      border: 1px solid #d2d2d4;
      padding: 12px 80px;
      border-radius: 30px;
      font-size: 18px;
      font-weight: 500;
      margin-top: 15px;
      margin-bottom: 30px;
      display: flex;
      align-items: center;

      img {
        width: 25px;
        margin-right: 10px;
      }
      @media only screen and (max-width: 1400px) and (min-width: 1200px) {
        font-size: 16px;
        padding: 12px 80px;
        border-radius: 30px;
        margin-top: 15px;
        margin-bottom: 30px;
        img {
          width: 22px;
          margin-right: 8px;
        }
      }
      @media screen and (max-width: 767px) {
        font-size: 16px;
        padding: 8px 40px;
        border-radius: 30px;
        margin-top: 15px;
        margin-bottom: 30px;
        img {
          width: 20px;
          margin-right: 8px;
        }
      }
    }
  }
}