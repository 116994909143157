.leave_overview_contentt{
 
    height: 180px;
}
.View_Queries {
width: 200px;
height: 220px;
}



.chatbox_dot{
    white-space: nowrap; 
    width: 218px; 
    overflow: hidden;
    text-overflow: ellipsis; 
 
    // border: 1px solid #000000;
}
.chat_container{
    padding: 10px; 
    // padding-bottom: 30px;
    font-size: 12px;
    border-bottom: 1px solid #DCDCDC;
}
.filter{
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid rgb(177, 177, 177);
}

.filter_icon{
  margin: 12px 1px;
  padding: 3px 8px
}
.filter_heading{
  //  text-align: center;
   margin-top: 10px;
    font-size: 20px;
    padding: 10px;
    // border-bottom: 0.5px solid rgb(177, 177, 177);
    color: var(--secondary);
    font-weight: bold;

}

.border_divider {
    border-left: 1px solid #000;
    // box-shadow: 2px 3px  #888888;
    @media screen and (max-width: 992px) {
      border-top: 1px solid #000;
      border-left: none;
      margin-top: 1rem;//separation of big card during responsive
    }
  }

  .chatbox{
      border-radius: 16px;
      background-color: white;
      position: relative;
      height: 98%
  
  }


  .subject_reply{
    color: var(--secondary);
    font-weight: bold;
    // text-align: center;
    margin-top: 10px;
    font-size: 13px;
    &_data{
      font-size: 13px;
    }
   
  }
  .chat_info{
      font-size: 13px;
  }
  // .subject_reply{
  //   text-align: center;
  //   margin-top: 10px;
  //   font-size: 20px;
  // }
  .chat_inner_info{
    display: flex;
    justify-content: space-between;
    padding: 4px;
  }

 
    @media screen and (max-width: 1199px) {
        .card{
        margin-top: 20px;//separation of big card during responsive
      }
  }
@media screen and (min-width: 1200px){
  .top_height{
    width: 29%;
    font-size: 12;
}
}
@media screen and (min-width: 1200px){
  .top_width{
    width: 71%;
}
}
@media screen and (min-width: 1400px){
.container{
  max-width: 1472px;
}
}
@media screen and (min-width: 1800px){
  .container{
    max-width: 1600px;
  }
  }
// @media screen and (min-width: 1400px)
// .container{}
  .card_hover:hover{
    // background-color: #dcdcdc87;
    // box-shadow: 4px 0px 5px 5px #c1c1c1;
    cursor: pointer;
    // border-bottom: 1px solid #959595;
    // border-top: 1px solid #959595;
  }
  .date{
    //   position: absolute;
    //   right: 0px;
    //   width: 500px;
    color: rgb(184, 172, 172);
    display: flex;
    flex-direction: row-reverse;

  }
  .status{
    color: rgb(65, 122, 230);
    padding: 2px;
    // border: 1px solid rgb(155, 155, 231);
    border-radius: 4px;
    font-weight: bold;
    // background-color: rgb(65, 122, 230);
  }
  .chat_inner_message{
    text-align: justify;
   
  }
  .chatbox_scrollbar  {
    // &_contents {
      height:85%;   //height of a card for scrolling
      // max-height: 570px;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        
        width: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #c2cbdf;
  
        box-shadow: inset 0 0 2px #C2CBDF;
        border-radius: 10px;
      }
  }

  .card_scoll  {
    // &_contents {
      height: 65vh;   //height of a card for scrolling
    
     
      overflow-y: auto;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        
        width: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #c2cbdf;
  
        box-shadow: inset 0 0 2px #C2CBDF;
        border-radius: 10px;
      }
  }

  .chat-inner{
    .New{
      font-weight: bold;
      color: var(--red);
    }
    .In-Progress{
      font-weight: bold;
      color: var(--blue);
    }
    .Resolved{
      font-weight: bold;
      color: var(--green);
    }
    .Reopened{
      font-weight: bold;
      color: var(--red);
    }

  }

  .separated_Name{
margin-top: 0px;
margin-bottom: 0px;
  }
  .unread{
    height: 7px;
  width: 7px;
  background-color: var(--secondary);
  border-radius: 50%;
  display: inline-block;
  }
  .visited{
    background-color: 	#DCDCDC;
  }

 .top_height{
  height: 90vh
 }
 .card_chat_container{
   height: 87vh;
   .card_chat{
     height: 100%;
     .card{
       height: 100%;
     }
   }
 }