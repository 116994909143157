:root {
  --primary: #FFFFFF;
  --secondary: #d87d2e;
  --secondary-light: #242034;
  --secondary-dark:#191624;
  --bg-color: #F1F4FB;
  --blue:#4a70ec;
  --green:rgb(30, 182, 30);
  --red:rgb(233, 95, 95);
  // --bg-color: #EEF2F5;
}