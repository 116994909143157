.wrapper {
  position: relative;
}

.input {
  .rc-time-picker-input {
    display: block;
    height: 40px; // 42px;
    line-height: 32px;
    font-size: 13px;
    -webkit-appearance: none;
    font-weight: 300;
    width: 100%;
    color: #070707;
    text-overflow: ellipsis;
    border-radius: 4px;
    border: none;
    background-color: var(--white);
    outline: var(--blue-button);
    font-family: Poppins, sans-serif;

    @media only screen and (max-width: 1400px) and (min-width: 1200px) {
      height: 36px;
      line-height: 28px;
      font-size: 11px;
    }

    &::placeholder {
      // color: #000;
      font-size: 13px;
      font-weight: 300;
      // opacity: 0.5;
      @media only screen and (max-width: 1400px) and (min-width: 1200px) {
        font-size: 11px;
      }
    }
    &_error {
      background-color: #fff3f5;
      border: solid 1px rgba(255, 0, 28, 0.25);
    }
    // &:focus {
    //   // font-size: 13px;
    //   border: solid 1px var(--secondary);
    // }
  }
}

.disabled {
  background-color: #eaeaea;
}

.label {
  font-size: 12px;
  margin-bottom: 3px;
  font-weight: bold;
  line-height: normal;
  margin-left: 6px;
  display: block;
  @media only screen and (max-width: 1400px) and (min-width: 1200px) {
    font-size: 11px;
  }
}

.error {
  text-transform: none;
  position: absolute;
  top: calc(100% + 2px);
  font-size: 10px;
  color: red;
  @media only screen and (max-width: 1400px) and (min-width: 1200px) {
    font-size: 9px;
  }
}

.lg {
  height: 45px; // 42px;
  font-size: 15px;
  @media screen and (max-width: 580px) {
    height: 40px; // 42px;
    font-size: 13px;
  }
}
.sm {
  width: 130px;
}
.xs {
  width: 110px;
}

.rc-time-picker-panel-inner {
  margin-top: 40px;
}

.rc-time-picker-panel-input-wrap {
  display: none;
}

.rc-time-picker-clear-icon {
  &:after {
    display: none;
  }
}

.rc-time-picker-panel-select {
  font-family: Poppins,"Consolas", sans-serif;
  font-size: 14px;
  cursor: pointer;

  // &::-webkit-scrollbar {
  //   width: 0 !important;
  //   height: 0 !important;
  // }
}

.rc-time-picker-panel-select li {
  padding: 0 0 0 10px;
}
