.interview_schedule_description {
  max-height: 80px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  display: block;
  overflow-x: auto;

  width: 100%;
  border: 0;
  .description_detail {
    font-size: 16px;
    @media only screen and (max-width: 1400px) and (min-width: 1200px) {
      font-size: 14px;
    }
  }
  &::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 10px;
    border: 1px solid #ededed;
    background-color: #ededed;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a6a6a6;
    box-shadow: inset 0 0 2px #a6a6a6;
    border-radius: 10px;
  }
}

.interview_status {
  position: relative;

  &_error {
    font-size: 10px;
    color: red;
    position: absolute;
    width: 100%;
    height: 100%;
    top: calc(100%);
  }
}

.interview_schedule {
  h6 {
    font-size: 18px;
    @media only screen and (max-width: 1400px) and (min-width: 1200px) {
      font-size: 16px;
    }
  }
  &_table {
    table {
      margin: 0;
      width: 100%;
      font-size: 11.5px;
      border: 1px solid #000;

      tbody {
        background-color: var(--bg-color);
      }
    }
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    display: block;
    overflow-x: auto;
    width: 100%;
    border: 0;
    &::-webkit-scrollbar {
      height: 10px;
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: none;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #c2cbdf;
      box-shadow: inset 0 0 2px #c2cbdf;
      border-radius: 10px;
    }
  }
}

.interview_schedule_title {
  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
}

.notification_container {
  position: relative;
  .notification_dots {
    position: absolute;
    top: -5px;
    right: -8px;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    background-color: orange;
  }
}

.table_tabs {
  .nav-item.nav-link.active {
    .notification_dots {
      background-color: #fff;
    }
  }
}

.interview_schedule_detail {
  h5 {
    font-size: 18px;
    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }

  &_list {
    // max-height: 285px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    display: block;
    overflow-x: auto;
    &::-webkit-scrollbar {
      height: 10px;
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: none;
      border-radius: 10px;
      border: 1px solid #ededed;
      background-color: #ededed;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #a6a6a6;
      box-shadow: inset 0 0 2px #a6a6a6;
      border-radius: 10px;
    }

    li {
      padding: 0.5rem;
      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
}

.today_date {
  .card-body {
    @media only screen and (max-width: 1400px) and (min-width: 1200px) {
      font-size: 18px;
    }
  }
}
