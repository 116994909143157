.user_profile {
  .card {
    border-radius: 12px;
  }
  .family_heading {
    color: var(--secondary);
    margin-bottom: 15px;
    font-weight: bold;
  }
}

.edit_profile_container {
  display: flex;
  align-items: center;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
  img {
    margin-right: 15px;
    height: 80px;
    aspect-ratio: auto 80 / 80;
    width: 80px;
    // @media screen and (max-width: 767px) {
    //   height: 50px;
    //   aspect-ratio: auto 50 / 50;
    //   width: 50px;
    // }
    @media only screen and (max-width: 1400px) and (min-width: 1200px) {
      height: 70px;
      aspect-ratio: auto 70 / 70;
      width: 70px;
    }
  }
  .user_name {
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
    margin-top: 5px;
    margin-bottom: 5px;
    @media screen and (max-width: 767px) {
      font-size: 20px;
      line-height: 25px;
      margin-top: 15px;
    }
    @media only screen and (max-width: 1400px) and (min-width: 1200px) {
      font-size: 25px;
      line-height: 30px;
    }
  }
}

.familyMember_Container {
  .card {
    border-radius: 13px;
    margin-top: 1.5rem;
    .card-header {
      background-color: transparent;
      margin-bottom: 0 !important;
      color: var(--secondary);
      border-bottom: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // padding: 0.7rem 2rem;

      .card-title {
        margin-bottom: 0 !important;
        @media screen and (max-width: 500px) {
          font-size: 16px;
        }
      }

      .family_table {
        // height: 300px;
        // max-height: 300px;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        display: block;
        overflow-x: auto;
        width: 100%;
        border: 0;
        &::-webkit-scrollbar {
          height: 10px;
          width: 10px;
        }

        &::-webkit-scrollbar-track {
          box-shadow: none;
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #c2cbdf;
          box-shadow: inset 0 0 2px #c2cbdf;
          border-radius: 10px;
        }
      }
    }
  }
}
