.imp__document {
  &--title {
    color: var(--secondary);
    font-size: 20px;
  }
  &--container {
    color: var(--secondary);
    font-size: 16px;
    // min-height: 220px;
    // @media only screen and (max-width: 1400px) and (min-width: 1200px) {
    //   min-height: 190px;
    // }
  }
  &--content {
    margin-right: 20px;
    margin-bottom: 10px;
  }

  .panel-wrap {
    margin-bottom: 20px;
  }

  .panel-wrap button {
    border: none;
    width: 100%;
  }
  .panel-wrap .panel-header {
    background: #f8f9fa;
    border: 1px solid #dee2e6;
  }

  .panel-wrap .panel-header button {
    color: var(--primary);
  }

  .panel-wrap.active-panel .panel-header {
    background: #f8f9fa;
    //color: #fff;
  }
  .panel-wrap.active-panel .panel-header button {
    border: none;
  }

  .panel-header {
    border: 1px solid #dee2e6;
    font-weight: normal;
    border-radius: 5px 5px 0px 0px;
    height: 3.3rem;
    //padding: 0.7rem;
    margin: -6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 13px;
    padding-top: 25px;
    color: #495057;
    p {
      font-size: 14px;
      font-weight: 600;
    }
  }

  .panel-header button {
    border: none;
    background: transparent;
  }
  .panel-header button:focus {
    outline: 0px auto -webkit-focus-ring-color;
  }

  .panel-body {
    border: 1px solid #dddddd;
    background: var(--primary);
    color: #333333;
    padding: 2rem;
    padding-top: 1rem;
    border-top: none;
  }

  .accordion {
    margin-top: 1.5rem;
  }
}
